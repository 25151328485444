<template>
  <div>
    <h4>아이템 리스트</h4>

    <el-button type="primary" @click="$router.push({ name: 'items-add' })">아이템 추가</el-button>

    <el-form :inline="true" @submit.prevent="retrieveItems" style="margin-top: 20px">
      <el-form-item>
        <el-input v-model="query" placeholder="아이템 이름으로 검색" clearable />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="retrieveItems">
          <el-icon style="vertical-align: middle">
            <Search />
          </el-icon>
          <span style="vertical-align: middle">검색</span>
        </el-button>
      </el-form-item>
    </el-form>

    <el-table :data="items">
      <el-table-column prop="category.name" label="카테고리" />
      <el-table-column>
        <template #default="scope">
          <el-image
            v-if="scope.row.imageUrl"
            :src="scope.row.imageUrl"
            style="width: 150px; height: 150px"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="이름" />
      <el-table-column prop="description" label="설명" />
      <el-table-column label="가격">
        <template #default="scope">
          $ {{ scope.row.price }}
        </template>
      </el-table-column>
      <el-table-column>
        <template #default="scope">
          <el-button @click="$router.push({ name: 'items-edit', params: { id: scope.row.id }})">
            수정
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination 
      v-model:currentPage="currentPageNumber"
      v-model:page-size="currentPageSize"
      @current-change="retrieveItems" 
      @size-change="retrieveItems"
      :total="total" 
      layout="sizes, total, ->, prev, pager, next" 
    />
  </div>
</template>

<script>
import utils from "@/helper/utils.js"
import ItemService from "@/services/ItemService"

export default {
  data() {
    return {
      query: '',
      items: [],
      currentPageNumber: 1,
      currentPageSize: 20,
      total: 0,
    }
  },
  methods: {
    async retrieveItems() {
      try {
        const resp = await ItemService.getAll(this.query, this.currentPageNumber, this.currentPageSize)
        this.items = resp.data.rows
        this.total = resp.data.count;
        console.log("items: ", this.items);
      } catch (e) {
        utils.handleHttpError(e, this)
      }
    },
  },
  mounted() {
    this.retrieveItems()
  }
}
</script>

<style>
</style>